<template>
  <v-container
    fluid
    fill-height
    :class="$style.auth"
    class="d-flex justify-center align-center flex-column"
  >
    <v-overlay :value="processing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <v-row>
        <v-col cols="12" class="text-center">
          <img :src="require('@/assets/logo.png')" width="320" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="d-flex justify-center">
          <v-card
            elevation="0"
            color="#fff"
            class="py-0 pa-4 rounded-xl"
            rounded
            :class="$style.form"
            width="360"
          >
            <error-list :errorMessages="errorMessages" :names="['message']" />
            <v-row dense>
              <v-col cols="12">
                <MyForm
                  :formKey="formKey"
                  :entity="entity"
                  :input="input"
                  ref="form"
                >
                  <v-container class="pa-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="code"
                          label="認証コード"
                          clearable
                          autocomplete="off"
                          :error-messages="
                            errorMessages.code && errorMessages.code[0]
                          "
                          background-color="#fff"
                        >
                          <v-icon slot="prepend">
                            mdi-lock
                          </v-icon>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </MyForm>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="10">
                <anchor label="もう一度送る" @click="requestCodeOneMore" />
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="10">
                <v-btn
                  class="text-center"
                  rounded
                  block
                  large
                  outlined
                  @click="onLogin"
                  >送信する</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import MyForm from "@/entries/front/components/form";
import ErrorList from "@/entries/front/components/error/error-list";
export default {
  components: {
    MyForm,
    ErrorList
  },
  data() {
    return {
      formKey: "code",
      input: {
        code: ""
      }
    };
  },
  computed: {
    code: {
      get() {
        return this.entity["code"];
      },
      set(value) {
        this.$store.commit("auth/setEntity", {
          entity: {
            code: value
          }
        });
      }
    },
    isError() {
      return this.$store.getters["auth/isError"];
    },
    processing() {
      return this.$store.getters[`auth/processing`];
    },
    entity() {
      return this.$store.getters[`auth/entity`];
    },
    errorMessages() {
      return this.$store.getters[`auth/errorMessages`];
    }
  },
  methods: {
    onLogin() {
      this.$store
        .dispatch("auth/code", {
          code: this.code
        })
        .then(() => {
          this.$store.commit("form/clearDirty");
          this.$router.push({ name: "menu" });
        })
        .catch(() => {});
    },
    requestCodeOneMore() {
      console.log("clicked");
    },
    popstate() {
      window.history.pushState(null, null, null);
      return;
    }
  },
  created() {
    this.$store.commit(`auth/initialize`);
  },
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
    this.$store.commit("form/changeDirty", [
      {
        key: this.formKey,
        isDirty: !!this.code
      }
    ]);
  }
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";

.form {
  background-color: #ffffff;
  width: calc(#{$app-width} / 2);
  min-width: 300px;
  padding: 15px 30px;
}

.error {
  color: #ff1100;
  text-align: center;
  font-size: 12px;
}

.reissue-link {
  font-size: 16px;
  text-decoration-line: none;
  color: $base-font-color !important;
}
</style>
